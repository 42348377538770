<div class="project-plan-wrap" [ngClass]="{'has-empty-value': isEmpty, 'disabled': disabled}">
  <div class="table-wrapper">
    <p-table [value]="projectPlanData" [(selection)]="selectedProjectPlanRows">
      <ng-template pTemplate="header">
        <tr>
          <td class="p-element"></td>
          <td class="p-element" style="width: 25%">{{ descriptionHeader }}</td>
          <td class="p-element" style="width: 25%">{{ startDateHeader }}</td>
          <td class="p-element" style="width: 25%">{{ endDateHeader }}</td>
          <td class="p-element" style="width: 25%">{{ durationHeader }}</td>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-projectPlanRow>
        <tr>
          <td class="table-checkbox">
            <app-checkbox-button
              [(state)]="projectPlanRow.selected"
              (stateChange)="onRowSelect($event, projectPlanRow)"
              [disabled]="disabled"
              ariaLabel="Select to mark row for deletion via delete button in the end of the table"
            ></app-checkbox-button>
          </td>
          <td>
            <input
              *ngIf="!disabled"
              pInputText
              autoResize="autoResize"
              type="text"
              (change)="handleChange()"
              (keyup)="removeLastWords(projectPlanRow)"
              [disabled]="disabled"
              [readonly]="disabled"
              [(ngModel)]="projectPlanRow.description"
              [placeholder]="descriptionPlaceholder"
              (keydown.ArrowRight)="stopPropagation($event)"
              (keydown.Arrowleft)="stopPropagation($event)"
              [attr.aria-label]="descriptionPlaceholder"
            />
            <p *ngIf="disabled">{{ projectPlanRow.description }}</p>
          </td>
          <td>
            <div class="p-field p-field-custom">
              <div class="p-float-label">
                <p-calendar
                  [(ngModel)]="projectPlanRow.startDateObj"
                  appendTo="body"
                  showIcon="true"
                  dateFormat="mm-dd-yy"
                  [monthNavigator]="true"
                  dataType="string"
                  showButtonBar="true"
                  (onSelect)="handleStartDateChange($event, projectPlanRow)"
                  (onInput)="handleStartDateChange($event, projectPlanRow)"
                >
                </p-calendar>
                <label>Start Date</label>
              </div>
            </div>
          </td>
          <td>
            <div class="p-field p-field-custom">
              <div class="p-float-label">
                <p-calendar
                  [(ngModel)]="projectPlanRow.endDateObj"
                  appendTo="body"
                  showIcon="true"
                  dateFormat="mm-dd-yy"
                  [monthNavigator]="true"
                  dataType="string"
                  showButtonBar="true"
                  (onSelect)="handleEndDateChange($event, projectPlanRow)"
                  (onInput)="handleEndDateChange($event, projectPlanRow)"
                >
                </p-calendar>
                <label>End Date</label>
              </div>
            </div>
          </td>
          <td>
            <p>{{ projectPlanRow.duration ? projectPlanRow.duration + ' Days' : '' }}</p>
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="footer" let-car>
        <tr [ngClass]="{ errored: !this.totalIsValid }">
          <td></td>
          <td>
            {{ totalRequirements ? totalRequirements : "Total duration in days" }}
          </td>
          <td>
            <div class="p-field p-field-custom">
              <div class="p-float-label">
                <p-calendar
                  [(ngModel)]="earliestSelectedStartDateObj"
                  [disabled]="true"
                  appendTo="body"
                  showIcon="false"
                  dateFormat="mm-dd-yy"
                  [monthNavigator]="true"
                  [hideOnDateTimeSelect]="false"
                  dataType="string"
                  showButtonBar="true"
                >
                </p-calendar>
                <label>Start Date</label>
              </div>
            </div>
          </td>
          <td>
            <div class="p-field p-field-custom">
              <div class="p-float-label">
                <p-calendar
                  [(ngModel)]="latestSelectedEndDateObj"
                  [disabled]="true"
                  appendTo="body"
                  showIcon="false"
                  dateFormat="mm-dd-yy"
                  [monthNavigator]="true"
                  [hideOnDateTimeSelect]="false"
                  dataType="string"
                  showButtonBar="true"
                >
                </p-calendar>
                <label>End Date</label>
              </div>
            </div>
          </td>
          <td>
            {{ getTotal() }}
          </td>
        </tr>
      </ng-template>
    </p-table>

    <div class="footer-buttons-wrap" *ngIf="!disabled">
      <div class="btn-wrap">
        <p-button
          styleClass="button button-primary button-primary--medium"
          (click)="addNew()"
          [disabled]="this.projectPlanData.length === 10"
          ariaLabel="Add a new project plan row in the table above"
        >
          <span class="material-icons" aria-hidden="true">add</span>
          Add
        </p-button>
      </div>
      <div class="btn-wrap" [pTooltip]="showDeleteTooltip(this.selectedProjectPlanRows.length)">
        <p-button
          styleClass="button button-delete button-delete--medium"
          (click)="deleteSelectedProjectPlanRows()"
          [disabled]="this.projectPlanData.length === this.selectedProjectPlanRows.length || this.selectedProjectPlanRows.length === 0"
          [ariaLabel]="this.selectedProjectPlanRows.length === 0 ?
                      'Select rows with the checkbox from the table above to enable delete' :
                      this.selectedProjectPlanRows.length === 1 ?
                      'Delete ' + this.selectedProjectPlanRows.length + ' selected project plan row' :
                      this.projectPlanData.length === this.selectedProjectPlanRows.length ?
                      'You should leave at least one project plan row' :
                      'Delete ' + this.selectedProjectPlanRows.length + ' selected project plan rows'"
        >
          <span class="material-icons" aria-hidden="true">delete</span>
          Delete
        </p-button>
      </div>
    </div>
  </div>
</div>