import { inject, Injectable } from "@angular/core";
import {
  Auth,
  browserLocalPersistence,
  signInWithCustomToken,
  signInWithEmailAndPassword,
  User,
  user,
} from "@angular/fire/auth";
import {
  BehaviorSubject,
  firstValueFrom,
} from "rxjs";
import {
  CarrotSSOConfiguration,
  PublicConfigService,
} from "./public.config.service";
import { environment } from "src/environments/environment";
import { ImpersonationInfo, RpUser, SsoUser } from "../model/user.model";
import { DataService } from "./data.service";
import { SsoRegisterUserRequest } from "../model/auth.model";
import { GoogleTagManagerService } from "angular-google-tag-manager";
import { StorageService } from "./storage.service";
import { NavBarService } from "./nav-bar.service";
import { Location } from "@angular/common";
import { Router } from "@angular/router";

@Injectable({
  providedIn: "root",
})
export class SsoAuthService {
  private firebaseAuth = inject(Auth);
  private firebaseUser = user(this.firebaseAuth);
  private ssoConfiguration: CarrotSSOConfiguration;
  private _currentSsoUser: BehaviorSubject<SsoUser | null> = new BehaviorSubject(null);
  private _currentRpUser: BehaviorSubject<RpUser | null> = new BehaviorSubject(null);
  private _impersonationInfo: BehaviorSubject<ImpersonationInfo | null> = new BehaviorSubject(null);
  public impersonationInfo$ = this._impersonationInfo.asObservable();
  public currentSsoUser$ = this._currentSsoUser.asObservable();
  public currentRpUser$ = this._currentRpUser.asObservable();

  private LastLoginRedirectTimestampKey = "LastLoginRedirectTimestamp";
  private ImpersonationInfoKey = "ImpersonationInfoKey";

  isEmailVerified = false;

  // Initial requested page URL
  requestedURL = location.pathname;

  constructor(
    private publicConfigService: PublicConfigService,
    private dataService: DataService,
    private googleTagManagerService: GoogleTagManagerService,
    private navBarService: NavBarService,
    private storage: StorageService,
    private locationService: Location,
    private router: Router,
  ) {
    // set firebase persistence to local in order to not sign out user on page refresh
    this.firebaseAuth.setPersistence(browserLocalPersistence);
    this.ssoConfiguration = this.publicConfigService.getCarrotSSOConfig();
    this.checkImpersonationInfo();
  }

  /**
   * Init user session checks
   * If redirect is about to happen to SSO return false. This is used in APP_INITIALIZER to not load app root when redirect happens
   * @returns false when redirect will happen, true otherwise
   */
  async initializeUserSession() {
    const { ssosession, loginToken, returnUrl } = this.getURLSearchParamsAsObject();
    const firebaseUser = await firstValueFrom(this.firebaseUser);
    this.requestedURL = returnUrl || location.pathname;

    // if firebase user exist but email is not verified go to login page
    if (firebaseUser && !firebaseUser.emailVerified) {
      console.log("initilizeUserSession -> firebase user logged in -> email not verified");
      this.isEmailVerified = false;
      return true;
    }

    // if ssosession is true and we have loginToken from SSO sign in firebase and setup user
    if (ssosession === "true" && loginToken) {
      console.log("initilizeUserSession -> ssosession true -> sign in");
      const res = await signInWithCustomToken(this.firebaseAuth, loginToken);
      await this.setupUser(res.user, "sso", returnUrl);
      return true;
    }

    // if firebase user exist and should not redirect to sso because of token expired setup the user and return 
    if (firebaseUser && !this.shouldRedirectToSSO()) {
      console.log("initilizeUserSession -> firebase user logged in -> no action");
      await this.setupUser(firebaseUser, "platform");
      return true;
    }

    // if firebase user exist but should redirect to sso because of token expired go to SSO for new token and return false
    if (firebaseUser && this.shouldRedirectToSSO()) {
      console.log("initilizeUserSession -> firebase user logged in -> token expired -> redirect to sso");
      const jwt = await firebaseUser.getIdToken();
      this.redirectToSSOToSetSession(jwt);
      return false;
    }

    // if ssosession is undefined redirect to SSO to check for session
    if (!ssosession) {
      console.log("initilizeUserSession -> ssosession empty -> redirect to sso and check session");
      this.redirectToSSOToCheckForSession();
      return false;
    }

    // if ssosession is false do nothing (angular router will handle page automatically)
    if (ssosession === "false") {
      console.log("initilizeUserSession -> ssosession false -> angular router handle");
      this.navigateTo(this.requestedURL);
      return true;
    }

    return true
  }

  /**
   * Setup the user in the platform based on conditions
   * @param firebaseUser firebase user
   * @param loginFrom from where the user logged in
   * @param redirectTo redirect page after setup
   * @returns void
   */
  private async setupUser(
    firebaseUser: User,
    loginFrom: "platform" | "sso" = "platform",
    redirectTo?: string
  ) {
    // reload jwt token if login is from SSO since claims can be changed
    const tokenResult = await firebaseUser.getIdTokenResult(loginFrom === "sso");
    const firstname = tokenResult.claims["firstname"] as string;
    const lastname = tokenResult.claims["lastname"] as string;

    this.isEmailVerified = firebaseUser.emailVerified;

    // user did not verify email and redirect is not empty break user setup and redirect
    if (!this.isEmailVerified && redirectTo) {
      this.navigateTo(redirectTo);
      return;
    }

    // user did not verify email break user setup
    if (!this.isEmailVerified) {
      return;
    }

    // set status cookie for Webflow
    this.setUserStatusCookie(2);

    // if login is from sso set timestamp and register user in platform
    if (loginFrom === "sso") {
      this.setLastLoginRedirectTimestamp();
      await this.selfRegisterInPlatform();
    }

    // create sso user and advance _currentSsoUser state
    const ssoUser: SsoUser = {
      uid: firebaseUser.uid,
      firstName: firstname,
      lastName: lastname,
      fullName: `${firstname} ${lastname}`,
    };
    this._currentSsoUser.next(ssoUser);

    // load platform user 
    await this.reloadPlatformUser();

    if (redirectTo) {
      this.navigateTo(redirectTo);
    }
  }

  /**
   * Login from platform with email and password
   * @param email 
   * @param password 
   */
  public async loginWithCredentials(email: string, password: string) {
    // login into firebase
    const res = await signInWithEmailAndPassword(
      this.firebaseAuth,
      email,
      password
    );
    
    const { claims } = await res.user.getIdTokenResult();
    this.isEmailVerified = res.user.emailVerified;
    // email not verified break login and return
    if (!this.isEmailVerified) {
      return
    }

    // if this is first time user loggin in to send GTM track event
    if (!claims["rolesByClientId"] || !claims["rolesByClientId"][this.ssoConfiguration.ssoClientId]) {
      this.googleTagManagerService.pushTag({
          event: 'user_register_event',
          url: this.publicConfigService.rpBaseUrl
      });
    }
    // get firebase JWT and redirect to SSO to set session there
    const jwt = await res.user.getIdToken();
    this.redirectToSSOToSetSession(jwt);
  }

  /**
   * Login from platform with token
   * @param token 
   */
  public async loginWithCustomToken(token: string) {
    // login into firebase
    const res = await signInWithCustomToken(
      this.firebaseAuth,
      token
    );
    
    const { claims } = await res.user.getIdTokenResult();
    this.isEmailVerified = res.user.emailVerified;
    // email not verified break login and return
    if (!this.isEmailVerified) {
      return
    }

    // if this is first time user loggin in to send GTM track event
    if (!claims["rolesByClientId"] || !claims["rolesByClientId"][this.ssoConfiguration.ssoClientId]) {
      this.googleTagManagerService.pushTag({
          event: 'user_register_event',
          url: this.publicConfigService.rpBaseUrl
      });
    }
    // get firebase JWT and redirect to SSO to set session there
    const jwt = await res.user.getIdToken();
    this.redirectToSSOToSetSession(jwt);
  }

  /**
   * Redirect to SSO to check for session there
   */
  private redirectToSSOToCheckForSession() {
    let url = `${this.ssoConfiguration.ssoBaseUrl}/api/v1/sso/meRedirect?clientId=${this.ssoConfiguration.ssoClientId}&returnUrl=${this.requestedURL}`;
    if (environment.localDevSsoRedirect) {
      url = `${url}&localDev=true`;
    }
    window.location.href = url;
  }

  /**
   * Redirect to SSO to set session there if user logged into the platform, but no session found in SSO
   * @param jwt from firebase
   */
  private redirectToSSOToSetSession(jwt: string) {
    let url = `${this.ssoConfiguration.ssoBaseUrl}/api/v1/sso/loginredirectwithtoken?clientId=${this.ssoConfiguration.ssoClientId}&jwt=${jwt}&returnUrl=${this.requestedURL}`;
    if (environment.localDevSsoRedirect) {
      url = `${url}&localDev=true`;
    }
    window.location.href = url;
  }

  /**
   * Register user in platform after login from sso
   */
  private async selfRegisterInPlatform() {
    await firstValueFrom(
      this.dataService.get(
        `${this.publicConfigService.rpBaseUrl}/api/user/selfregister`
      )
    );
  }

  /**
   * Get search params from url as javascript object
   * @returns object
   */
  private getURLSearchParamsAsObject() {
    return Object.fromEntries(new URLSearchParams(window.location.search));
  }

  /**
   * Returns current ssoUser
   * @returns ssoUser
   */
  public getCurrentSsoUser() {
    return firstValueFrom(this.currentSsoUser$);
  }

  /**
   * Returns current rpUser
   * @returns rpUser
   */
  async getCurrentRpUser() {
    return firstValueFrom(this.currentRpUser$);
  }

  async startImpersonate(userId: string, firstName: string, lastName: string) {
    const response = await firstValueFrom(this.dataService.get(`${this.publicConfigService.rpBaseUrl}/api/user/impersonate/${userId}`));
    const currentSSOUser = await this.getCurrentSsoUser();
    
    const impersonationInfo = {
        impersonatedByFullName: `${currentSSOUser.firstName} ${currentSSOUser.lastName}`,
        impersonatedFullName: `${firstName} ${lastName}`
    };
    this.storage.store(this.ImpersonationInfoKey, JSON.stringify(impersonationInfo));
    this.checkImpersonationInfo();
    const res = await signInWithCustomToken(this.firebaseAuth, response.data.loginToken);
    await this.setupUser(res.user, "platform");
    this.router.navigate(["dashboard"]);

    // refreshing nav items after impersonation
    this.navBarService.refreshNav();
}

  async stopImpersonate() {
    await this.router.navigate(["dashboard"]);
    this.storage.removeCompletely(this.ImpersonationInfoKey);
    this._impersonationInfo.next(null);
    await this.logout('', false);
    // reload page to login with same user as before the impersonate
    location.reload();
  }

  /**
   * This gets the platform user and advances the _currentRpUser state
   * @returns rpUser
   */
  async reloadPlatformUser() {
    const { data: rpUser } = await firstValueFrom(this.dataService.getMe());
    this._currentRpUser.next(rpUser);
    return rpUser;
  }

  /**
   * Logout from firebase/sso and clear all states
   * @param logoutFromSSO default true
   * @param returnUrl where to go after sign out
   */
  async logout(returnUrl?: string, logoutFromSSO = true) {
    await this.firebaseAuth.signOut();
    this.setUserStatusCookie(1);
    this.removeLastLoginRedirectTimestamp();
    this.storage.removeCompletely(this.ImpersonationInfoKey);
    this._currentRpUser.next(null);
    this._currentSsoUser.next(null);
    if (returnUrl) {
      returnUrl = this.publicConfigService.rpBaseUrl + returnUrl;
    } else {
      returnUrl = this.publicConfigService.feBaseUrl;
    }
    if (logoutFromSSO) {
      window.location.href = `${this.ssoConfiguration.ssoBaseUrl}/app/${this.ssoConfiguration.ssoClientId}/logout?redirectUrl=${returnUrl}`;
    }
  }

  /**
   * Register user in SSO
   * @param registerUser 
   * @returns response
   */
  async registerUser(registerUser: SsoRegisterUserRequest) {
    const resp = await firstValueFrom(
      this.dataService.post(
        this.ssoConfiguration.ssoBaseUrl +
          "/api/v1/profile/selfregister?clientId=" +
          this.ssoConfiguration.ssoClientId,
        registerUser
      )
    );
    return resp;
  }

  /**
   * Resend email verify request
   * @returns response
   */
  async resendVerifyEmail() {
    const resp = await firstValueFrom(
      this.dataService.post(
        this.ssoConfiguration.ssoBaseUrl + "/api/v1/profile/sendVerifyEmail",
        { clientId: this.ssoConfiguration.ssoClientId }
      )
    );
    return resp;
  }

  /**
   * Verify email request
   * @param token 
   * @returns response
   */
  async verifyEmail(token: string) {
    const resp = await firstValueFrom(
      this.dataService.get(
        this.ssoConfiguration.ssoBaseUrl +
          "/api/v1/profile/verifyEmail?token=" +
          token
      )
    );
    return resp;
  }

  /**
   * Send forgot password email request
   * @param email 
   * @returns response
   */
  async sendForgotPasswordEmail(email: string) {
    const resp = await firstValueFrom(
      this.dataService.post(
        this.ssoConfiguration.ssoBaseUrl + "/api/v1/profile/passwordReset",
        { email, clientId: this.ssoConfiguration.ssoClientId }
      )
    );
    return resp;
  }

  /**
   * Get confirm password request
   * @param token 
   * @returns response
   */
  async getConfirmPasswordRequest(token: string) {
    const resp = await firstValueFrom(
      this.dataService.get(
        this.ssoConfiguration.ssoBaseUrl +
          "/api/v1/profile/confirmPasswordReset/" +
          token
      )
    );
    return resp;
  }

  /**
   * Get login token from SSO
   * @param current JWT 
   * @returns response
   */
  async getLoginTokenFromSSO(jwt: string) {
    const resp = await firstValueFrom(
      this.dataService.get(`${this.ssoConfiguration.ssoBaseUrl}/api/v1/sso/generateLoginToken?clientId=${this.ssoConfiguration.ssoClientId}&jwt=${jwt}`)
    );
    return resp;
  }

  /**
   * Post confirm password request
   * @param token 
   * @param newPassword 
   * @returns response
   */
  async postConfirmPasswordRequest(token: string, newPassword: string) {
    const resp = await firstValueFrom(
      this.dataService.post(
        this.ssoConfiguration.ssoBaseUrl +
          "/api/v1/profile/confirmPasswordReset/",
        { requestId: token, newPassword }
      )
    );
    return resp;
  }

  /**
   * Set user status cookie for Webflow to know if user is signed in 
   * @param value 1 user not signed in / 2 user signed in
   */
  private setUserStatusCookie(value: number) {
    // setting or updating userStatus cookie
    const cookieName = `${this.publicConfigService.getEnvironmentName().toUpperCase()}-userStatus`;
    const cookieValue = value;
    const hostnameArr = document.location.hostname.split('.');
    // remove first element from array (subdomain)
    hostnameArr.shift();
    // join array in a new string
    const domain = `.${hostnameArr.join('.')}`;
    document.cookie = `${cookieName}=${cookieValue};path=/;domain=${domain};samesite=none;Secure`;
  }

  /**
   * Set last login redirect timpestamp
   * This is used to know when was the last time the user signed in from SSO and not the platform
   */
  private setLastLoginRedirectTimestamp() {
    this.storage.store(this.LastLoginRedirectTimestampKey, new Date().getTime());
  }

  /**
   * Remove last login redirect timestamp
   */
  private removeLastLoginRedirectTimestamp() {
    this.storage.remove(this.LastLoginRedirectTimestampKey);
  }

  /**
   * Based on last login redirect timpestamp check if we should redirect to SSO for new token or not
   * @returns boolean
   */
  private shouldRedirectToSSO() {
    // if impersonation is in inprogress we don't need to redirect to SSO, only when rawImpersonationInfo is empty we redirect
    const rawImpersonationInfo = this.storage.retrieve(this.ImpersonationInfoKey);
    if (rawImpersonationInfo) {
      return false;
    }
    const lastLoginRedirectTimestamp = this.storage.retrieve(this.LastLoginRedirectTimestampKey);
    if (lastLoginRedirectTimestamp) {
      const twentyFourHours = environment.ssoLoginRedirectIntervalInHours * 60 * 60 * 1000;
      const currentTime = new Date().getTime();
      return (lastLoginRedirectTimestamp + twentyFourHours) < currentTime;
    }
    return true;
  }

  /**
   * Check for impersonation info
   */
  private checkImpersonationInfo() {
    const rawImpersonationInfo = this.storage.retrieve(this.ImpersonationInfoKey);
    if(rawImpersonationInfo) {
      const impersonationInfo = JSON.parse(rawImpersonationInfo);
      this._impersonationInfo.next(impersonationInfo);
    } 
  }

  /**
   * Get JWT from firebase
   * @param forceRefresh default false 
  */
  async getJwt(forceRefresh = false) {
    const firebaseUser = await firstValueFrom(this.firebaseUser);
    const token = await firebaseUser?.getIdToken(forceRefresh);
    return token;
  }

  /**
   * Refresh JWT with new one from SSO and reload user
   * @param oldJWT 
   * @returns newJWT
   */
  async hardRefreshJWT(oldJWT: string) {
    const { data: { loginToken } } = await this.getLoginTokenFromSSO(oldJWT);
    const res = await signInWithCustomToken(this.firebaseAuth, loginToken);
    this.setupUser(res.user, 'sso');
    const newJWT = await res.user.getIdToken();
    return newJWT;
  }

  /**
   * User history API through Angular location wrapper to navigate to URL since angular router is not reliable in APP_INIT
   */
  navigateTo(page: string) {
    this.locationService.replaceState(page);
  }
}
