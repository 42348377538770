import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { APP_INITIALIZER, ErrorHandler, Injector, NgModule } from "@angular/core";

import { AppRoutingModule } from "./app-routing.module";
import { SharedModule } from "./shared/shared.module";

import { AppComponent } from "./app.component";
import { LoaderComponent } from "./loader/loader.component";

/* Menu Items */
import {
  NgbDropdownModule,
  NgbModule,
  NgbTooltipModule
} from "@ng-bootstrap/ng-bootstrap";

import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { FormioModule } from "@formio/angular";
import { FormioGrid } from "@formio/angular/grid";
import { PublicConfigService } from "./shared/services/public.config.service";
import { MatIconModule } from "@angular/material/icon";
import { DragulaModule } from "ng2-dragula";
import { ErrorComponent } from "./error/error.component";
import { FooterComponent } from "./footer/footer.component";
import { LoaderStateInterceptor } from "./shared/interceptors/loaderStateInterceptor.interceptor";
import { FormsModule } from "./forms/forms.module";
import { NavBarComponent } from "./shared/components/nav-bar/nav-bar.component";
import { NotificationBarComponent } from "./shared/components/notification-bar/notification-bar.component";
import { ScrollToTopModule } from "./shared/components";
import { SubmissionsDropdownModule } from "./shared/components/submissions-dropdown/submissions-dropdown.module";
// import { GeneralSetupService } from "./shared/services/general-setup.service";


import { HttpClientInMemoryWebApiModule } from 'angular-in-memory-web-api';
import { InMemoryDataService } from './shared/services/in-memory-data.service';
import { EvaluationOverviewDetailsComponent } from "./stages/evaluation/overview-details/evaluation-overview-details.component";
import { PeerOverviewDetailsComponent } from "./stages/peer-review/overview-details/peer-overview-details.component";
import { AdminReviewViewReviewComponent } from "./stages/admin-review/review/view/admin-review-view-review.component";
import { AdminReviewEditReviewComponent } from "./stages/admin-review/review/edit/admin-review-edit-review.component";
import { EvaluationJudgingReviewComponent } from "./stages/evaluation/judging-review/evaluation-judging-review.component";
import { GuestComponent } from "./guest/guest.component";
import { RegistrationClosedComponent } from "./registration-closed/registration-closed.component";


import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { provideAnalytics, getAnalytics } from '@angular/fire/analytics';
import { provideAuth, getAuth, connectAuthEmulator } from '@angular/fire/auth';
import { provideFirestore, getFirestore, connectFirestoreEmulator } from '@angular/fire/firestore';
import { provideStorage, getStorage } from '@angular/fire/storage';
import { provideFunctions, getFunctions, connectFunctionsEmulator } from '@angular/fire/functions';
import { FIREBASE_OPTIONS } from '@angular/fire/compat';
import { LoginComponent } from "./auth/login/login.component";
import { ForgotPasswordComponent } from "./auth/forgot-password/forgot-password.component";
import { ChangePasswordComponent } from "./auth/change-password/change-password.component";
import { RegisterComponent } from "./auth/register/register.component";
import { VerifyEmailComponent } from "./auth/verify-email/verify-email.component";
import { GenericScreenComponent } from "./auth/generic-screen.component/generic-screen.component";
import { EmptyRedirectComponent } from "./empty-redirect-component/empty-redirect.component";
import { CommonService } from "./shared/services/common.service";

import * as Sentry from "@sentry/angular-ivy";
import { Router } from "@angular/router";
import { SsoAuthService } from "./shared/services/sso-auth.service";
import { firstValueFrom, from, switchMap, delayWhen, interval } from "rxjs";
import { CustomErrorHandler } from "./shared/handlers/customErrorHandler";
import { CustomToastComponent } from "./shared/components/custom-toast/custom-toast.component";
import { RichTextEditorComponent } from "./shared/components/rich-text-editor/rich-text-editor.component";

@NgModule({
    declarations: [
        AppComponent,
        NavBarComponent,
        LoaderComponent,
        ErrorComponent,
        GuestComponent,
        RegistrationClosedComponent,
        FooterComponent,
        NotificationBarComponent,
        EvaluationOverviewDetailsComponent,
        EvaluationJudgingReviewComponent,
        PeerOverviewDetailsComponent,
        AdminReviewViewReviewComponent,
        AdminReviewEditReviewComponent,
        LoginComponent,
        ForgotPasswordComponent,
        ChangePasswordComponent,
        RegisterComponent,
        VerifyEmailComponent,
        GenericScreenComponent,
        EmptyRedirectComponent,
    ],
    imports: [
        HttpClientModule,
        HttpClientInMemoryWebApiModule.forRoot(InMemoryDataService, { dataEncapsulation: false, passThruUnknownUrl: true }),
        BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        SharedModule,
        NgbDropdownModule,
        NgbTooltipModule,
        NgbModule,
        MatIconModule,
        FormioModule,
        FormioGrid,
        DragulaModule.forRoot(),
        ScrollToTopModule,
        RichTextEditorComponent,
        SubmissionsDropdownModule,
        // forms
        FormsModule,
        // custom standalone toast component
        CustomToastComponent,
    ],
    providers: [
        // interceptors
        {
            provide: HTTP_INTERCEPTORS,
            useClass: LoaderStateInterceptor,
            multi: true
        },

        // GTM
        { 
          provide: 'googleTagManagerId',
          deps: [PublicConfigService],
          useFactory: (publicConfigService: PublicConfigService) => publicConfigService.getGTMId()
        },

        // firebase
        provideFirebaseApp((injector: Injector) => {
          const publicConfigService = injector.get<PublicConfigService>(
            PublicConfigService
          );
          const carrotSSOConfig = publicConfigService.getCarrotSSOConfig()
          const app = initializeApp(carrotSSOConfig);
          if (carrotSSOConfig.useEmulators) {
      
            const auth = getAuth();
            connectAuthEmulator(auth, 'http://localhost:9099');
      
            const firestore = getFirestore();
            connectFirestoreEmulator(firestore, 'localhost', 8080);
      
            const functions = getFunctions();
            connectFunctionsEmulator(functions, 'localhost', 5001);
          }

          
          return app;
        }, PublicConfigService),

        provideAnalytics(() => getAnalytics()),
        provideAuth(() => getAuth()),
        provideFirestore(() => getFirestore()),
        provideStorage(() => getStorage()),
        provideFunctions(() => getFunctions()),
        // ScreenTrackingService,
        // UserTrackingService,
        {
          provide: APP_INITIALIZER,
          deps: [CommonService, SsoAuthService],
          useFactory: (commonService: CommonService, ssoAuthService: SsoAuthService) => 
            () => firstValueFrom(commonService.GetCompetitionInformation()
                    .pipe(
                      switchMap(() => from(ssoAuthService.initializeUserSession())),
                      // wait for 10000ms if there will be a redirect (value=false), just in case the browser takes longer to trigger href change
                      delayWhen(value => value ? interval(0) : interval(10000)),
                    )
                  ),
          multi: true
        },
        {
          provide: ErrorHandler,
          useValue: Sentry.createErrorHandler({
            showDialog: false,
          }),
        },
        {
          provide: Sentry.TraceService,
          deps: [Router],
        },
        {
          provide: ErrorHandler,
          useClass: CustomErrorHandler,
        }
    ],
    bootstrap: [AppComponent]
})
export class AppModule { 
  constructor(trace: Sentry.TraceService) {}
}
