<div class="fullscreen">
  <div class="container-stepper">
    <div class="header">
      <div class="heading">
        <h1>Reviewing: {{ participantName }}</h1>
      </div>
      <div class="actions">
        <div class="saved-date">
          {{ saving ? 'Saving...' : 'Last Saved: '}}
          {{ !saving && lastUpdated ? (lastUpdated | utcToCompTime: 'fullDateTime') : '' }}
        </div>
        <button class="button-p-menu-toggle" [matMenuTriggerFor]="menu" *ngIf="selectedStep > 0">
          <span class="label">Submissions</span>
          <span class="icon material-icons">keyboard_arrow_down</span>
        </button>
        <mat-menu #menu="matMenu" overlayPanelClass="test-menu" class="mat-menu-custom">
          <a [href]="submissionLink.url" [target]="submissionLink.target" mat-menu-item *ngFor="let submissionLink of formsArray">{{ submissionLink.label }}</a>
        </mat-menu>
        <button
          class="button button-secondary button-secondary--medium"
          [ngClass]="{'disabled-soft': hasUnsavedData}"
          (click)="saveAndExit()"
          [disabled]="hasUnsavedData"
          [tooltipDisabled]="!hasUnsavedData"
          pTooltip="Autosave is running"
          tooltipPosition="top"
          aria-label="Save and exit"
        >
          Save & Exit
        </button>
      </div>
    </div>

    <div class="content">
      <div class="content-inner">
        <div class="steps-wrap">
          <div class="steps-wrap-inner">
            <div
              class="step"
              [ngClass]="{
                'step-completed': step.completed === true,
                'step-current': i === selectedStep,
              }"
              *ngFor="let step of steps; let first = first; let last = last; let i = index;"
            >
              <button
                class="step-selector"
                (click)="selectionChanged(i)"
                [disabled]="hasUnsavedData || i > firstIncompleteStepIndex"
                [tooltipDisabled]="!hasUnsavedData || i > firstIncompleteStepIndex"
                pTooltip="Autosave is running"
              >
                <span class="step-number">
                  <span *ngIf="step.completed && i !== selectedStep" class="icon material-icons" aria-hidden="true">done</span>
                  <span *ngIf="!step.completed || i === selectedStep">{{ i + 1 }}</span>
                </span>
                <span class="step-title">{{ step.title }}</span>
                <span class="step-value" *ngIf="(step.rangeChanged || step.completed) && (step.score || step.score === 0)">
                  {{ step.type === 'trait' ? step.score.toFixed(1) : step.score }}
                </span>
              </button>
            </div>
          </div>
        </div>
        <div class="step-details-wrap">
          <ng-container *ngFor="let step of steps; let first = first; let last = last; let i = index;">
            <div class="step-details" *ngIf="i === selectedStep">
              <h2 class="step-title">{{ step.title }}</h2>
              <p class="step-description" [innerHTML]="step.description | formatLinks"></p>
              <div [ngSwitch]="step.type">
                <div *ngSwitchCase="'formsreview'" class="step-container step-form">
                  <a (click)="form.isRead = true; formsClicked(step, form.formStageId)" *ngFor="let form of step.forms" [href]="getFormUrl(form)" target="_blank">
                    <mat-icon fontSet="material-icons-round" [class.checked]="form.isRead">{{ form.isRead ? 'check_circle' : 'outbound' }}</mat-icon>
                    <span class="form-title">{{ form.title }}</span>
                  </a>
                </div>
                <div *ngSwitchCase="'coi'" class="step-container step-conflict">
                  <h3 class="step-subtitle">{{ step.question }}</h3>
                  <div class="checkbox-wrapper">
                    <mat-checkbox
                      class="conflict-checkbox"
                      [checked]="step.hasCoi === false"
                      (change)="step.hasCoi === false ? step.hasCoi = null : step.hasCoi = false"
                      (click)="coiChange(step)"
                    >
                      {{ step.nonAffirmatoryAnswer }}
                    </mat-checkbox>
                    <mat-checkbox
                      class="conflict-checkbox"
                      [checked]="step.hasCoi === true"
                      (change)="step.hasCoi === true ? step.hasCoi = null : step.hasCoi = true"
                      (click)="coiChange(step)"
                    >
                      {{ step.affirmatoryAnswer }}
                    </mat-checkbox>
                  </div>
                </div>
                <div *ngSwitchCase="'trait'" class="step-container step-slider">
                  <div class="slider-container">
                    <div class="anchor-low">{{ step.leftAnchor }}</div>
                    <div class="slider-wrapper slider-trait">
                      <mat-slider
                        min="0"
                        max="5"
                        step="0.1"
                        [displayWith]="sliderDisplay(step)"
                        tickInterval="10"
                        [discrete]="true"
                        [ngClass]="{'step-changed': step.rangeChanged}"
                      >
                      <input matSliderThumb
                        (input)="sliderChange(step, $event.target.value)"
                        [(ngModel)]="step.score"
                        (ngModelChange)="sliderModelChange(step)"
                        aria-label="Trait input slider"
                      >
                      </mat-slider>
                      <div class="slider-ticks">
                        <span class="slider-tick" [ngClass]="step.score >= 0 ? 'active' : ''">
                          <span class="tick-top"></span>
                          <span class="tick-bottom">0</span>
                        </span>
                        <span class="slider-tick" [ngClass]="step.score >= 1 ? 'active' : ''">
                          <span class="tick-top"></span>
                          <span class="tick-bottom">1</span>
                        </span>
                        <span class="slider-tick" [ngClass]="step.score >= 2 ? 'active' : ''">
                          <span class="tick-top"></span>
                          <span class="tick-bottom">2</span>
                        </span>
                        <span class="slider-tick" [ngClass]="step.score >= 3 ? 'active' : ''">
                          <span class="tick-top"></span>
                          <span class="tick-bottom">3</span>
                        </span>
                        <span class="slider-tick" [ngClass]="step.score >= 4 ? 'active' : ''">
                          <span class="tick-top"></span>
                          <span class="tick-bottom">4</span>
                        </span>
                        <span class="slider-tick" [ngClass]="step.score >= 5 ? 'active' : ''">
                          <span class="tick-top"></span>
                          <span class="tick-bottom">5</span>
                        </span>
                      </div>
                    </div>
                    <div class="anchor-high">{{ step.rightAnchor }}</div>
                  </div>
                  <div class="range-definition">
                    <div class="range-definition-inner">
                      <div [innerHTML]="step.rangeDefinition | formatLinks"></div>
                    </div>
                  </div>
                  <mat-form-field appearance="outline" class="feedback-field">
                    <mat-label>Feedback</mat-label>
                    <textarea #feedbackTextarea matInput [(ngModel)]="step.comment" (ngModelChange)="feedbackChange(step.comment, step)"></textarea>
                    <mat-hint align="end">Words Count: {{ countFeedbackWords(feedbackTextarea.value) || 0 }} (Minimum of {{ wordCount }} Words)</mat-hint>
                  </mat-form-field>
                </div>
                <div *ngSwitchCase="'overallimpression'" class="step-container step-slider">
                  <div class="slider-container">
                    <div class="slider-wrapper slider-overall-impression">
                      <mat-slider
                        min="0"
                        max="100"
                        step="1"
                        [displayWith]="sliderDisplay(step)"
                        tickInterval="20"
                        [discrete]="true"
                        [ngClass]="{'step-changed': step.rangeChanged}"
                      >
                      <input matSliderThumb
                        (input)="sliderChange(step, $event.target.value)"
                        [(ngModel)]="step.score"
                        (ngModelChange)="sliderModelChange(step)"
                        aria-label="Overall impression input slider"
                      >
                      </mat-slider>
                      <div class="slider-ticks">
                        <span class="slider-tick" [ngClass]="step.score >= 0 ? 'active' : ''">
                          <span class="tick-top"></span>
                          <span class="tick-bottom">0</span>
                        </span>
                        <span class="slider-tick" [ngClass]="step.score >= 20 ? 'active' : ''">
                          <span class="tick-top"></span>
                          <span class="tick-bottom">20</span>
                        </span>
                        <span class="slider-tick" [ngClass]="step.score >= 40 ? 'active' : ''">
                          <span class="tick-top"></span>
                          <span class="tick-bottom">40</span>
                        </span>
                        <span class="slider-tick" [ngClass]="step.score >= 60 ? 'active' : ''">
                          <span class="tick-top"></span>
                          <span class="tick-bottom">60</span>
                        </span>
                        <span class="slider-tick" [ngClass]="step.score >= 80 ? 'active' : ''">
                          <span class="tick-top"></span>
                          <span class="tick-bottom">80</span>
                        </span>
                        <span class="slider-tick" [ngClass]="step.score >= 100 ? 'active' : ''">
                          <span class="tick-top"></span>
                          <span class="tick-bottom">100</span>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div *ngSwitchCase="'submit'" class="step-container step-summary">
                  <table class="summary-table">
                    <thead>
                      <tr>
                        <th>Criteria</th>
                        <th>Score</th>
                        <th>Feedback</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let sum of step.summary; let first = first; let last = last; let i = index">
                        <td>
                          <button
                            class="go-to-step"
                            *ngIf="!last"
                            (click)="goToStep(sum.step)"
                            attr.aria-label="Go to step {{ sum.criteria }}"
                          >
                            {{ sum.criteria }}
                          </button>
                          <span *ngIf="last">{{ sum.criteria }}</span>
                        </td>
                        <td>{{ sum.score }}</td>
                        <td>
                          <div class="summary-feedback-wrap" [ngClass]="{'expanded': sum.expanded === true}">
                            <button class="expand-trigger" *ngIf="sum.showFeedbackOverlay" (click)="expandFeedback(sum, i)" attr.aria-label="Expand feedback for {{ sum.criteria }}">
                              <span class="icon material-icons" aria-hidden="true">expand_more</span>
                            </button>
                            <div class="summary-feedback" [ngClass]="{'behind-overlay': sum.showFeedbackOverlay === true}">
                              <p>{{ sum.feedback }}</p>
                            </div>
                            <div class="summary-feedback-overlay" *ngIf="sum.showFeedbackOverlay">
                              <p>{{ sum.feedback }}</p>
                            </div>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div class="step-errors" *ngIf="step?.errors?.length > 0">
                <ul>
                  <li *ngFor="let error of step.errors">
                    {{ error.text }}
                  </li>
                </ul>
              </div>
              <div class="review-actions">
                <button class="button button-secondary" (click)="previous()" *ngIf="!first" aria-label="Back">Back</button>
                <button
                  class="button button-primary"
                  [ngClass]="{'disabled-soft': hasUnsavedData}"
                  (click)="next(step)"
                  *ngIf="!last"
                  [disabled]="hasUnsavedData"
                  [tooltipDisabled]="!hasUnsavedData"
                  pTooltip="Autosave is running"
                  tooltipPosition="top"
                  aria-label="Next"
                >
                  Next
                </button>
                <button class="button button-primary" (click)="saveAndExit()" *ngIf="last" aria-label="Save and exit">Save & Exit</button>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>
