import { FormioCustomComponent, FormioEvent } from "@formio/angular";
import {
  Input,
  Output,
  EventEmitter,
  Component,
  ViewChild,
} from "@angular/core";
import { DataService } from "src/app/shared/services/data.service";
import { PublicConfigService } from "../../../shared/services/public.config.service";
import { Subscription } from "rxjs";
import { FormSubmissionService } from "src/app/forms/services/form-submission.service";

@Component({
  selector: "fileUpload",
  templateUrl: "./fileUpload.component.html",
  styleUrls: ["./fileUpload.component.scss"]
})
export class FileUploadComponent implements FormioCustomComponent<string> {
  @ViewChild('formFileUpload') formFileUpload;

  private initialized = false;
  private _value: string;
  @Input()
  public set value(v: string) {
    this._value = v;
    this.init();
  }

  public get value(): string {
    return this._value;
  }

  public get isValid(): boolean {
    let result = false;
    if (!this.required && (this.isControlEmpty || this.fileUrl)) {
      result = true;
    } else if (this.required && !this.isControlEmpty && this.fileUrl) {
      result = true;
    }
    return result;
  }

  public fileUrl = "";

  // used to check if the control has a file or no
  public isControlEmpty: boolean;

  private fileNameToUse: string = '';
  public fileNameToDisplay: string = '';
  private fileSizeInMb: number = 0;
  public fileSizeToDisplay: string = '';

  @Output()
  valueChange = new EventEmitter<string>();

  @Output()
  formioEvent = new EventEmitter<FormioEvent>();

  @Input()
  disabled: boolean;

  @Input()
  allowedFileType: string;

  @Input()
  allowedFileSize: string;

  @Input()
  required: boolean;

  uploadFileSubscription: Subscription | undefined;
  uploadInProgress: boolean = false;

  hide: boolean = false;
  showFileUploadSection: boolean = false;

  contentText: string = "";

  currentFiles: any = [];

  uploadedNow: boolean = false;

  tryingToUploadEmptyFile: boolean = false;

  constructor(
    private publicConfigService: PublicConfigService,
    private dataService: DataService,
    private formSubmissionService: FormSubmissionService
  ) { }

  getAllowedFileType() {
    if (this.allowedFileType) {
      var allowedTypes = this.allowedFileType.split(",");
      for (var i = 0; i < allowedTypes.length; i++) {
        allowedTypes[i] = "." + allowedTypes[i].trim();
      }
      return allowedTypes.join(",");
    }
    return "";
  }

  getAllowedMaxSize() {
    if (this.allowedFileSize) {
      let maxFileSize = Number(this.allowedFileSize);
      return maxFileSize * 1024 * 1024;
    }
  }

  getFileSize(file) {
    this.fileSizeInMb = file.size / (1024 * 1024);
    this.fileSizeToDisplay = `${this.fileSizeInMb.toFixed(2)} MB`;
  }

  getAllowedTypeMessage() {
    return `You can only upload a file in the following formats: ${this.allowedFileType}`;
  }

  getMaxFileSizeMessage() {
    return `You cannot upload a file larger than ${this.allowedFileSize} MB`;
  }

  async handleUpload($event) {
    this.getFileSize($event.files[0]);
    const existingFileUrl = this.fileUrl;
    const currentFormUserId = this.formSubmissionService.userId;
    const containerName = currentFormUserId;

    
    // disabled when file upload is in progress
    if (!this.uploadInProgress) {
      this.uploadInProgress = true;
      let file = $event.files[0];
      this.hide = false;
      this.contentText = `File upload in progress...`;
      this.uploadFileSubscription = this.dataService
        .postFile(file, containerName)
        .subscribe(
          (response) => {
            this.uploadInProgress = false;
            if (response.errors && response.errors.length > 0) {
              this.contentText = "File upload was interrupted. Please try again";
              response.errors.forEach((element) => {
                if (element.error === "Potential Malware detected") {
                  this.contentText =
                    "File did not pass security check. Please try again.";
                }
              });
              this.isControlEmpty = true;
              this.setValue("", this.contentText);
            } else {
              this.isControlEmpty = false;
              this.setValue(response.data, "");
              this.contentText = `${file.name} uploaded successfully!`;
              if(existingFileUrl){
                this.dataService.deleteFile(existingFileUrl).toPromise();
              }
              
              this.showFileUploadSection = false;
              this.uploadedNow = true;
            }
          },
          (err) => {
            this.showFileUploadSection = false;
            this.uploadInProgress = false;
            this.contentText = "File upload was interrupted. Please try again";
            this.isControlEmpty = true;
            this.setValue("", this.contentText);
          }
        );
    }
  }

  setValue(value: string, error: string) {
    this.fileUrl = value;
    this.getFileNameFromUrl(this.fileUrl);
    const fileUploadData = {
      value: { fileUrl: this.fileUrl, fileName: this.fileNameToUse },
      valid: this.isValid,
      fileSizeInMb: this.fileSizeInMb,
      validationMessage: error,
    };
    this.value = JSON.stringify(fileUploadData);
    this.valueChange.emit(this.value);
    this.formioEvent.emit({ eventName: "change", data: { isChanged: true } });
  }

  onSelect($event) {
    if ($event.currentFiles.some(f => f.size === 0)) {
      this.tryingToUploadEmptyFile = true;
      return;
    }
    this.tryingToUploadEmptyFile = false;
    // disabled when file upload is in progress
    if (!this.uploadInProgress) {
      // if the file type is not in the accepted file format, the below check will return 0
      if (($event.currentFiles.length > 0)) {
        this.currentFiles = $event.currentFiles;
        this.hide = true;
        this.contentText = "";
        this.showFileUploadSection = true;
        // clicking on primeng hidden upload button automatically
        // to perform upload
        setTimeout(() => {
          const newObj = {
            files: $event.currentFiles
          }
          // upload file directly
          this.handleUpload(newObj);
        }, 100);
      } else {
        this.hide = true;
        this.contentText = "";
        this.showFileUploadSection = false;
      }
    }
  }

  onClear($event) {
    // disabled when file upload is in progress
    if (!this.uploadInProgress) {
      if (this.uploadFileSubscription) {
        this.uploadFileSubscription.unsubscribe();
      }

      if (this.fileUrl) {
        this.dataService.deleteFile(this.fileUrl).subscribe((resp) => {
          this.contentText = "";
          this.uploadedNow = false;
        });
      } else {
        this.hide = true;
        this.contentText = "";
      }
      // reset file size and value
      this.fileSizeInMb = 0,
      this.fileSizeToDisplay = '';
      this.setValue("", "File upload is required.");
    }
  }

  getFileNameFromUrl(url: string) {
    if (url) {
      var urlObject = new URL(url);
      urlObject.search = "";

      var urlWithoutQueryString = urlObject.toString();
      this.fileNameToUse = urlWithoutQueryString.split("/").pop();
      this.fileNameToDisplay = this.fileNameToUse.replace(/%20/g, ' ');
    } else {
      this.fileNameToUse = '';
      this.fileNameToDisplay = '';
    }
  }

  // this runs twice on initial load
  // and runs on upload/delete
  private init() {
    if (this.value && !this.initialized) {
      const fileJSON = JSON.parse(this.value)
      const fileUploadData = fileJSON.value;
      this.fileUrl = fileUploadData.fileUrl;
      this.initialized = true;
      this.hide = true;
      if (fileJSON.fileSizeInMb) {
        this.fileSizeInMb = fileJSON.fileSizeInMb;
        this.fileSizeToDisplay = `${this.fileSizeInMb.toFixed(2)} MB`;
      };
      this.getFileNameFromUrl(this.fileUrl);
    }
    this.isControlEmpty = !this.value || !this.fileUrl;
  }

  clickOnSelectButton() {
    // disabled when file upload is in progress
    if (!this.uploadInProgress) {
      // this clicks on the p-button that opens the system file select window
      this.formFileUpload.el.nativeElement.querySelector('.p-fileupload-choose').click();
    }
  }

  clickOnCancelButton() {
    // disabled when file upload is in progress
    if (!this.uploadInProgress) {
      this.currentFiles.pop();
      this.showFileUploadSection = false;
    }
  }
}
