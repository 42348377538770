export default [
    {
      type: "textfield",
      input: true,
      required: "true",
      key: "customOptions.totalRequirements",
      weight: 10,
      label: "Total Requirements Text",
      placeholder: "Total Requirements Text",
      tooltip: "The text displayed in the last row, against the Total sum.",
    },
    {
      type: "number",
      input: true,
      required: "true",
      key: "customOptions.maxRows",
      weight: 10,
      label: "Maximum Rows",
      placeholder: "10",
      tooltip: "Maximum number of rows that could be added for the table.",
      defaultValue: 10,
    },
    {
      type: "number",
      input: true,
      required: "true",
      key: "customOptions.maxDescriptionWords",
      weight: 10,
      label: "Maximum Description Words",
      placeholder: "25",
      tooltip: "Maximum number of words that could be added for the description field of each row.",
      defaultValue: 25,
    },
    {
      type: "textfield",
      input: true,
      required: "true",
      key: "customOptions.descriptionHeader",
      weight: 10,
      label: "Serves as Column name for Task Description",
      placeholder: "Task Description/Name",
      tooltip: "",
      defaultValue: "Task Decription",
    },
    {
      type: "textfield",
      input: true,
      required: "true",
      key: "customOptions.descriptionPlaceholder",
      weight: 10,
      label: "Serves as Placeholder for the input field “Task Description”",
      placeholder: "",
      tooltip: "",
      defaultValue: "Task Description Placeholder",
    },
    {
      type: "textfield",
      input: true,
      required: "true",
      key: "customOptions.startDateHeader",
      weight: 10,
      label: "Serves as Column name for Start Date.",
      placeholder: "Start Date",
      tooltip: "",
      defaultValue: "Start Date",
    },
    {
      type: "textfield",
      input: true,
      required: "false",
      key: "customOptions.earliestStartDate",
      weight: 10,
      label: "Serves to establish which is the earliest possible date to start a task.",
      placeholder: "THIS WILL BE A DATEPICKER",
      tooltip: "",
    },
    {
      type: "textfield",
      input: true,
      required: "true",
      key: "customOptions.endDateHeader",
      weight: 10,
      label: "Serves as Column name for End Date.",
      placeholder: "End Date",
      tooltip: "",
      defaultValue: "End Date",
    },
    {
      type: "textfield",
      input: true,
      required: "false",
      key: "customOptions.latestEndDate",
      weight: 10,
      label: "Serves to establish which is the latest possible date to complete a task.",
      placeholder: "THIS WILL BE A DATEPICKER",
      tooltip: "If Client has a strict deadlines for the execution of the project (i.e. to be completed before Dec 31st)",
    },
    {
      type: "textfield",
      input: true,
      required: "true",
      key: "customOptions.durationHeader",
      weight: 10,
      label: "Serves as Column name for Task Duration.",
      placeholder: "Task Duration",
      tooltip: "",
      defaultValue: "Task Duration",
    }
  ];
  