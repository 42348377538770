<div class="fullscreen">
  <div class="container-view-review">
    <div class="header">
      <div class="heading">
        <h1>Reviewing: {{ participantName }}</h1>
      </div>
      <div class="actions">
        <button class="button-p-menu-toggle" [matMenuTriggerFor]="menu">
          <span class="label">Submissions</span>
          <span class="icon material-icons">keyboard_arrow_down</span>
        </button>
        <mat-menu #menu="matMenu" overlayPanelClass="test-menu" class="mat-menu-custom">
          <a [href]="submissionLink.url" [target]="submissionLink.target" mat-menu-item *ngFor="let submissionLink of formsArray">{{ submissionLink.label }}</a>
        </mat-menu>
        <button *ngIf="!redistributionAlreadyRan" class="button button-secondary button-secondary--medium" [disabled]="isDeadlinePassed" (click)="edit()">Edit</button>
        <button class="button button-primary button-primary--medium" (click)="exit()">Exit</button>
      </div>
    </div>

    <div class="content">
      <div class="content-inner">
        <h5 class="table-title">Summary</h5>
        <div class="table-description" [innerHTML]="description | formatLinks"></div>
        <div class="table-wrapper">
          <table class="summary-table">
            <thead>
              <tr>
                <th>Criteria</th>
                <th>Score</th>
                <th>Feedback</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let sum of summary; let first = first; let last = last; let i = index">
                <td>
                  <span>{{ sum.criteria }}</span>
                </td>
                <td>{{ sum.score }}</td>
                <td>
                  <div class="summary-feedback-wrap" [ngClass]="{'expanded': sum.expanded === true}">
                    <button class="expand-trigger" *ngIf="sum.showFeedbackOverlay" (click)="expandFeedback(sum, i)" attr.aria-label="Expand feedback for {{ sum.criteria }}">
                      <span class="icon material-icons" aria-hidden="true">expand_more</span>
                    </button>
                    <div class="summary-feedback" [ngClass]="{'behind-overlay': sum.showFeedbackOverlay === true}">
                      <p>{{ sum.feedback }}</p>
                    </div>
                    <div class="summary-feedback-overlay" *ngIf="sum.showFeedbackOverlay">
                      <p>{{ sum.feedback }}</p>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>    