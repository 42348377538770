import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
  ElementRef,
  ViewChildren,
  QueryList,
  OnInit,
} from "@angular/core";
import { FormioCustomComponent, FormioEvent } from "@formio/angular";
import { ProjectPlanData } from "../models/projectPlanData";
import { MessageService } from "primeng/api";
import { CellEditor, TableEditCancelEvent, TableEditInitEvent } from "primeng/table";
import { DateTime } from 'luxon';
import { DatePipe } from "@angular/common";

@Component({
  selector: "projectPlan",
  templateUrl: "./projectPlan.component.html",
  styleUrls: ["./projectPlan.component.scss"],
})
export class ProjectPlanComponent implements OnInit, FormioCustomComponent<string> {
  projectPlanData: ProjectPlanData[] = [];

  totalIsValid = true;

  private initialized = false;

  private _value: string;
  @Input()
  public set value(v: string) {
    this._value = v;
    this.init();
  }

  public get value(): string {
    return this._value;
  }

  public get isEmpty(): boolean {
    // No value available or the value contains no project plan row/s
    return !this.value || this.projectPlanData.length === 0 ||
    // The value contains one project plan row, which is the default/empty one
    ( 
      this.projectPlanData.length === 1 &&
      !this.projectPlanData[0].description &&
      !this.projectPlanData[0].startDate &&
      !this.projectPlanData[0].startDateObj &&
      !this.projectPlanData[0].endDate &&
      !this.projectPlanData[0].endDateObj &&
      !this.projectPlanData[0].duration
    );
  }

  public get isValid(): boolean {
    let result = false;
    if (!this.required && (this.isEmpty || this.checkValueIsValid())) {
      result = true;
    }
    else if (this.required && !this.isEmpty && this.checkValueIsValid()) {
      result = true;
    }
    return result;
  }

  @Output()
  valueChange = new EventEmitter<string>();

  @Output()
  formioEvent = new EventEmitter<FormioEvent>();

  @Input()
  disabled: boolean;

  @Input()
  required: boolean;

  @Input()
  totalRequirements: string;

  @Input()
  maxRows: number;

  @Input()
  maxDescriptionWords: number;

  @Input()
  descriptionHeader: string;

  @Input()
  descriptionPlaceholder: string;

  @Input()
  startDateHeader: string;

  @Input()
  earliestStartDate: string;

  @Input()
  endDateHeader: string;

  @Input()
  latestEndDate: string;

  @Input()
  durationHeader: string;

  @Input()
  totalValue: string;

  @Input()
  totalValidationRule: string;

  earliestSelectedStartDate: string = null;
  earliestSelectedStartDateObj: any = null
  latestSelectedEndDate: string = null;
  latestSelectedEndDateObj: any = null;


  customMessage: string =
    "Please revise your project plan table. Some of the requirements are not met.";

  selectedProjectPlanRows: ProjectPlanData[];
  total: number = 0;

  error: string = "";

  constructor(
    private messageService: MessageService,
    private datePipe: DatePipe,
  ) {
    this.selectedProjectPlanRows = [];
  }

  ngOnInit(): void {
    if (!this.projectPlanData.length) {
      this.projectPlanData.push({
        id: 0,
        description: "",
        startDate: "",
        startDateObj: null,
        endDate: "",
        endDateObj: null,
        duration: 0,
      });
    };
  }

  private setValue(val: string = null) {
    const valid = this.checkValueIsValid();
    if (valid) {
      this.clearError();
    }

    const projectPlanDataToSave = {
      value: this.projectPlanData,
      valid: valid,
    };

    projectPlanDataToSave.value.forEach(element => {
      delete element['selected'];
    });

    console.log(projectPlanDataToSave);
    this.value = JSON.stringify(projectPlanDataToSave);
    this.valueChange.emit(this.value);
    this.formioEvent.emit({ eventName: "change", data: { isChanged: true } });
  }

  deleteSelectedProjectPlanRows() {
    if (this.projectPlanData.length == this.selectedProjectPlanRows.length) {
      return;
    }
    this.projectPlanData = this.projectPlanData.filter(
      (val) => !this.selectedProjectPlanRows.includes(val)
    );
    this.selectedProjectPlanRows = [];
    this.getTotal();
    this.handleChange();
  }

  addNew() {
    if (this.projectPlanData.length == 10) {
      return;
    }
    if (this.projectPlanData.length) {
      this.projectPlanData.push({
        id: this.projectPlanData[this.projectPlanData.length - 1].id + 1,
        description: "",
        startDate: "",
        startDateObj: null,
        endDate: "",
        endDateObj: null,
        duration: 0,
      });
    } else {
      this.projectPlanData.push({
        id: 0,
        description: "",
        startDate: "",
        startDateObj: null,
        endDate: "",
        endDateObj: null,
        duration: 0,
      });
    }
  }

  checkValueIsValid() {
    return true;
    // this.validateRows();
    // if (!this.validateTotal() || !this.checkRowsAreValid()) {
    //   return false;
    // }

    // return true;
  }

  checkRowsAreDefault() {
    for (let i = 0; i < this.projectPlanData.length; i++) {
      if (
        this.projectPlanData[i].description !== "" ||
        this.projectPlanData[i].startDate !== "" ||
        this.projectPlanData[i].startDateObj !== null ||
        this.projectPlanData[i].endDate !== "" ||
        this.projectPlanData[i].endDateObj !== null ||
        this.projectPlanData[i].duration !== null
      ) {
        return false;
      }
    }
    return true;
  }

  validateTotal() {
    if (this.totalValidationRule === "=") {
      let totalValueNum = Number(this.totalValue);
      if (this.total !== totalValueNum) {
        this.totalIsValid = false;
        return false;
      }
    }

    if (this.totalValidationRule === ">=") {
      let totalValueNum = Number(this.totalValue);
      if (this.total < totalValueNum) {
        this.totalIsValid = false;
        return false;
      }
    }

    if (this.totalValidationRule === "<=") {
      let totalValueNum = Number(this.totalValue);
      if (this.total > totalValueNum) {
        this.totalIsValid = false;
        return false;
      }
    }

    if (this.totalValidationRule === ">=, <=") {
      let numbers = this.totalValue.split(",");
      let min = Number(numbers[0]);
      let max = Number(numbers[1]);

      if (min > this.total || this.total > max) {
        this.totalIsValid = false;
        return false;
      }
    }

    this.totalIsValid = true;
    return true;
  }

  checkRowsAreValid() {
    for (let i = 0; i < this.projectPlanData.length; i++) {
      if (
        !this.projectPlanData[i].description ||
        !this.projectPlanData[i].startDate ||
        !this.projectPlanData[i].endDate ||
        !this.projectPlanData[i].duration
      ) {
        return false;
      }
    }
    return true;
  }

  validateRows() {
    let rowsAreValid = true;
    for (let i = 0; i < this.projectPlanData.length; i++) {
      this.validateProjectPlanRowDescription(i);
      this.validateProjectPlanRowStartDate(i);
      this.validateProjectPlanRowEndDate(i);
    }
    return rowsAreValid;
  }

  removeLastWords(projectPlanRow) {
    let res = projectPlanRow.description.split(/\s+/);
    if (res.length > 25) {
      res = res.splice(0, 25);
    }
    projectPlanRow.description = res.join(" ");
  }

  handleStartDateChange(event, projectPlanRow) {
    projectPlanRow.startDate = this.datePipe.transform(projectPlanRow.startDateObj, 'yyyy-MM-ddTHH:mm:ss');

    if (projectPlanRow.startDate) {
      this.calculateEarliestStartDate();
    }

    if (projectPlanRow.startDate && projectPlanRow.endDate) {
      this.calculateDuration(projectPlanRow);
    } else {
      projectPlanRow.duration = 0;
    }
  }

  handleEndDateChange(event, projectPlanRow) {
    projectPlanRow.endDate = this.datePipe.transform(projectPlanRow.endDateObj, 'yyyy-MM-ddTHH:mm:ss');

    if (projectPlanRow.endDate) {
      this.calculateLatestEndDate();
    }

    if (projectPlanRow.startDate && projectPlanRow.endDate) {
      this.calculateDuration(projectPlanRow);
    } else {
      projectPlanRow.duration = 0;
    }
  }

  calculateEarliestStartDate() {
    this.projectPlanData.forEach(projectPlanRow => {
      // if start date exists in row
      if (projectPlanRow.startDate) {
        // if earliest start date exists
        if (this.earliestSelectedStartDate) {
          // check if row start date is earlier than earliest start date
          if (projectPlanRow.startDate < this.earliestSelectedStartDate) {
            // if so - assign
            this.earliestSelectedStartDate = projectPlanRow.startDate;
            this.earliestSelectedStartDateObj = projectPlanRow.startDateObj;
          }
        } else {
          // if earliest start date does not exist - assign it
          this.earliestSelectedStartDate = projectPlanRow.startDate;
          this.earliestSelectedStartDateObj = projectPlanRow.startDateObj;
        }
      }
    })
  }

  calculateLatestEndDate() {
    this.projectPlanData.forEach(projectPlanRow => {
      // if start date exists in row
      if (projectPlanRow.endDate) {
        // if earliest start date exists
        if (this.latestSelectedEndDate) {
          // check if row start date is earlier than earliest start date
          if (projectPlanRow.endDate > this.latestSelectedEndDate) {
            // if so - assign
            this.latestSelectedEndDate = projectPlanRow.endDate;
            this.latestSelectedEndDateObj = projectPlanRow.endDateObj;
          }
        } else {
          // if earliest start date does not exist - assign it
          this.latestSelectedEndDate = projectPlanRow.endDate;
          this.latestSelectedEndDateObj = projectPlanRow.endDateObj;
        }
      }
    })
  }

  calculateDuration(projectPlanRow) {
    let startDate = DateTime.fromISO(projectPlanRow.startDate);
    let endDate = DateTime.fromISO(projectPlanRow.endDate);

    // add one extra day to include the currently selected end date day
    projectPlanRow.duration = endDate.diff(startDate, ["days"]).toObject().days + 1;
    console.log(projectPlanRow);
  }

  validateProjectPlanRowDescription(i: number) {
    return true;
  }

  validateProjectPlanRowStartDate(i: number) {
    return true;
  }

  validateProjectPlanRowEndDate(i: number) {
    return true;
  }

  getTotal() {
    var sum = 0;
    for (let i = 0; i < this.projectPlanData.length; i++) {
      if (this.projectPlanData[i].duration) {
        sum += this.projectPlanData[i].duration;
      }
    }

    if (sum > 0) {
      this.total = sum;
      return `${this.total} Days`; 
    } else {
      this.total = 0;
      return '';
    }
  }

  handleChange() {
    this.setValue();
  }

  private clearError() {
    setTimeout(() => {
      var errors = document.getElementsByClassName("error");
      if (errors.length > 1) {
        for (let i = 0; i < errors.length; i++) {
          if (errors[i].innerHTML === this.customMessage) {
            errors[i].setAttribute("style", "display:none;");
            break;
          }
        }
      }
    }, 250);
  }

  private init() {
    if (this.value && !this.initialized) {
      this.projectPlanData = JSON.parse(this.value).value;
      this.projectPlanData.forEach(element => {
        delete element['selected'];
      });
      this.getTotal();
      this.checkValueIsValid();
      this.initialized = true;
    }
  }

  onRowSelect(typeOfSelection, item) {
    if (typeOfSelection == true) {
      this.selectedProjectPlanRows.push(item);
    } else if (typeOfSelection == false) {
      let removeIndex = this.selectedProjectPlanRows.map(el => el.id).indexOf(item.id);
      if (removeIndex >= 0) {
        this.selectedProjectPlanRows.splice(removeIndex, 1);
      };
    }
  }

  showDeleteTooltip(selectedProjectPlanRowsLength) {
    let tooltip: string = ``;
    if (selectedProjectPlanRowsLength === 0) {
      tooltip = 'You must select at least one row to delete'
    } else {
      if (selectedProjectPlanRowsLength === this.projectPlanData.length) {
        tooltip = 'You must leave at least one row present'
      };
    }
    return tooltip;
  }

  stopPropagation(e: any) {
    // this is called to prevent primeng built-in behaviour of switching active (focused) input
    // when in edit mode the left/right arrows are pressed
    // this effectively restores standard behaviour of moving the cursor inside the input field, changing the selected character
    e.stopPropagation();
  }
}
