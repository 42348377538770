import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from "@angular/router";
import { Observable } from "rxjs";
import { SsoAuthService } from "../services/sso-auth.service";

@Injectable({
  providedIn: 'root'
})
export class RedirectIfLoggedInGuard  {

  constructor(
    private router: Router,
    private ssoAuthService: SsoAuthService
    ) {}
  
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    
    return this.runChecks(route);
  }

  async runChecks(route) {
    const rpUser = await this.ssoAuthService.getCurrentRpUser();
    
    if(rpUser) {
        this.router.navigate(['/dashboard']);
        return false;
    }

    return true;
  }
}
