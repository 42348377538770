export default [
  {
    key: "validate.custom",
    calculateValue(context) {
      return "valid = (!input && !component.validate.required) || (input && JSON.parse(input).valid) || 'Please revise your {{component.label}}. Some of the requirements are not met.'";
    },
  },
  {
    type: "textfield",
    input: true,
    key: "customOptions.maxDuration",
    weight: 10,
    label: "Maximum Duration Requirement",
    placeholder: "Maximum Duration Requirement",
    validate: {
      required: true,
    },
    tooltip:
      "Add two numbers with a coma (number, number) for validation with “between”. Decimal separators not allowed. Enter “no” if there is no total duration requirement.",
  },
  {
    type: "select",
    input: true,
    key: "customOptions.maxDurationValidationRule",
    label: "Maximum Duration Validation Rule",
    tooltip: "Select “no” if there are no total validation requirements.",
    weight: 20,
    placeholder: "Total Value Validation Rule",
    dataSrc: "values",
    validate: {
      required: true,
    },
    data: {
      values: [
        { label: "Equal", value: "=" },
        { label: "Less than or equal to", value: "<=" },
        { label: "Greater than or equal to", value: ">=" },
        { label: "Between", value: ">=, <=" },
        { label: "No", value: "no" },
      ],
    },
  },
];
