import { Injector } from '@angular/core';
import { FormioCustomComponentInfo, registerCustomFormioComponent } from '@formio/angular';
import { ProjectPlanComponent } from './projectPlan.component';
import baseEditForm from "formiojs/components/_classes/component/Component.form";
import projectPlanEditData from './editForm/projectPlan.edit.data';
import projectPlanEditValidation from './editForm/projectPlan.edit.validation';

const COMPONENT_OPTIONS: FormioCustomComponentInfo = {
  type: 'projectPlan',
  selector: 'project-plan',
  title: 'ProjectPlan',
  group: 'basic',
  icon: 'fa fa-table',
  editForm: projectPLanEditForm,
  extraValidators: ['custom'],
  schema: {
    customDefaultValue: "{}"
  }
};

export function projectPLanEditForm() {
  const form = baseEditForm([
    {
      key: "display",
      components: projectPlanEditData,
    },
    {
      key: "validation",
      components: projectPlanEditValidation,
    },
  ]);
  return form;
}

export function registerProjectPlanComponent(injector: Injector) {
  try{
    registerCustomFormioComponent(COMPONENT_OPTIONS, ProjectPlanComponent, injector);
  }
  catch (err) {
    // custom component already exists
  }
}
